$c-primary: #71c9c1;
$c-secandary: #15384f;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BasisGrotesquePro', sans-serif;
$font-family-heading: 'BasisGrotesquePro', sans-serif;

      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formInputRow {
  position: relative;

  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 35%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}

.control {
  background-color: white;
  border-bottom: 1px solid rgba(25, 25, 25, 0.32);
  border-radius: 0;
  padding-left: 35%;
}
