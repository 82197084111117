$c-primary: #71c9c1;
$c-secandary: #15384f;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BasisGrotesquePro', sans-serif;
$font-family-heading: 'BasisGrotesquePro', sans-serif;

      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  height: 100%;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;



  .description {
    text-align: left;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
