$c-primary: #71c9c1;
$c-secandary: #15384f;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BasisGrotesquePro', sans-serif;
$font-family-heading: 'BasisGrotesquePro', sans-serif;

      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BasisGrotesquePro';
        src: url('/fonts/BasisGrotesquePro-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.heading {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  margin-bottom: 1.5rem; //24
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem; //-0.5px
}
